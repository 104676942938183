import { User, CreditCard } from "react-feather"
import { useNavigate } from "react-router"
import usePayments from "../hooks/usePayments"
import { VerifyPayments } from "../api/student"
import { useState } from "react"
import { toast } from "react-toastify"
import useDetails from "../hooks/useDetails"
const SmallCard = ({title, paymentStatus, invoice}) => {
    const {data:student} = useDetails()
    
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    console.log(paymentStatus, 'Omohhh')

    const paymentRequery = async (ref) => {
        if (!ref) return alert('No Payment Reference Found')
        try {
            setLoading(true)
            toast.loading("Requering in Progress")
            const response = await VerifyPayments(ref);
            setLoading(false)
            window.location.reload()
        } catch (error) {
          toast.error('Verification Failed')
          setLoading(false)
        }
    }

    
  return (
    <>
    <div class="card report-card">
        <div className={`card-body ${student?.payment_complete === null?
                "dspg-danger"
                    : (student?.payment_complete.toLowerCase() === "full") ? "dspg-success"
                        : (student?.payment_complete.toLowerCase() === "part") ? 'dspg-warning'
                            : ''
                            }`}>
            <div class="row d-flex justify-content-center">
                <div class="col-8">
                    <p class="text-dark font-weight-semibold font-14">{title}</p>
                    <div>
                    <h3 class="">{student?.payment_complete === null ? "Not Paid"
                        : student?.payment_complete.toLowerCase() === "full" ? "Paid"
                            :student?.payment_complete.toLowerCase() === "part" ? "Paid"
                                : "Not-Paid"}</h3>
                                <h6>{student?.payment_complete === null ? "" : student?.payment_complete.toLowerCase() === 'part' ? "(Part Payment)" : student?.payment_complete.toLowerCase() === 'full' ? "(Full Payment)" : ""}</h6>
                    </div>
                    {/* {(paymentStatus?.payment_status === "success" && paymentStatus?.paystack_ref) ? 
                    <button class="btn btn-light" onClick={() => navigate('/invoice/school-fee', {state: { invoice } })}>Print Receipt</button>
                            : (paymentStatus?.payment_status === "pending" && paymentStatus?.paystack_ref) ?
                            <button class="btn btn-warning" disabled={loading} onClick={() => paymentRequery(paymentStatus?.paystack_ref)}>Requery</button>
                            : null
                    } */}
                </div>
                <div class="col-4 align-self-center">
                    <div class="report-main-icon bg-light-alt">
                        <CreditCard class="align-self-center icon-dual-pink icon-lg"></CreditCard>  
                    </div>
                </div>
            </div>
        </div> 
    </div>
    
    </>
  )
}

export default SmallCard